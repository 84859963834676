@use '../../../styles/modules' as m;

.text-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: m.$br-default;
  font-size: 1.4rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;

  &.icon-left {
    padding-right: 1.6rem;

    svg {
      margin-right: 0.2rem;
    }
  }

  &.icon-right {
    padding-left: 1.6rem;

    svg {
      margin-left: 0.2rem;
    }
  }

  span {
    padding-bottom: 0.1rem;
  }
}
