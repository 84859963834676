@use '../../../styles/modules' as m;
@use 'sass:math';

header.site-header {
  @include m.transition(padding);

  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  padding: 2.4rem 2.4rem 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: none;
  transform: none;

  nav {
    display: flex;
    flex: 0 0 auto;
    border-radius: m.$br-default;

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 56px;

      &.nav-btn {
        padding-left: m.nav-spacing('xxs');
        padding-right: m.nav-spacing('xxs');
      }

      &.name {
        font-weight: bold;
        font-size: 1.8rem;
        border-radius: m.$br-inset;
      }

      &.menu-btn {
        padding: 0;
        width: 56px;
        justify-content: center;
        border-radius: m.$br-inset;
      }
    }

    &.primary-nav {
      a {
        padding-bottom: 2px;
      }
    }

    &.secondary-nav {
      position: relative;
      margin-left: 0.8rem;

      .icon-button-group {
        display: flex;

        .icon-btn {
          padding: 2rem;
          position: relative;
          border-radius: 0;

          &:first-child {
            border-radius: 0 0 0 m.$br-inset;
          }

          &#theme {
            border-radius: 0 0 m.$br-inset 0;
          }

          // Divider - works out to 8px on top/bottom
          &:not(#theme) {
            &::after {
              content: '';
              width: 1px;
              height: calc(100% - 18px);
              position: absolute;
              right: 0;
              bottom: auto;
              top: auto;
              transform: translateY(-1px);
            }
          }
        }
      }
    }

    &.menu-nav {
      display: flex;
      flex-direction: column;
      margin-top: 8px;
      border-radius: m.$br-default;
      position: absolute;
      top: 100%;
      right: 0;
      width: 224px;

      > a.btn {
        display: flex;
        align-items: center;
        padding: 1.6rem;
        min-width: 48px;
        min-height: 56px;
        position: relative;
        margin-bottom: 1px;

        &:first-child {
          border-radius: m.$br-inset m.$br-inset 0 0;
        }

        // Divider - works out to 8px on left/right
        &::after {
          content: '';
          position: absolute;
          left: auto;
          right: auto;
          bottom: -1px;
          width: calc(100% - 20px);
          height: 1px;
        }
      }

      .icon-button-group {
        display: flex;

        a,
        button {
          flex: 1;
          justify-content: center;
          border-radius: 0;
          padding: 0;
          min-height: 56px;

          &:first-child {
            border-radius: 0 0 0 m.$br-inset;
          }

          &:last-child {
            border-radius: 0 0 m.$br-inset 0;
          }
        }
      }
    }
  }
}

@include m.xs {
  header.site-header {
    nav .btn.nav-btn {
      padding-left: m.nav-spacing('xs');
      padding-right: m.nav-spacing('xs');
    }

    nav.menu-nav {
      margin-left: 50%;
    }
  }
}

@include m.sm {
  header.site-header {
    nav {
      &.primary-nav {
        .nav-btn {
          border-radius: 0;

          &.name {
            border-radius: m.$br-inset 0 0 m.$br-inset;
          }

          &:last-child {
            border-radius: 0 m.$br-inset m.$br-inset 0;
          }
        }
      }

      &.secondary-nav {
        .icon-button-group {
          .icon-btn {
            &:first-child {
              border-radius: m.$br-inset 0 0 m.$br-inset;
            }

            &#theme {
              border-radius: 0 m.$br-inset m.$br-inset 0;
            }
          }
        }
      }
    }
  }
}
