@use '../../../styles/modules' as m;

footer {
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 1.2rem 0;
  transition: padding m.$timing ease-out;

  .footer-info {
    font-size: 1.4rem;
    padding: 0 0.8rem;
  }

  .text-link {
    height: initial;
  }
}

@include m.xs {
  footer {
    padding: 1.6rem;
  }
}

@include m.sm {
  footer {
    padding: 2.4rem;
  }
}
