@use '../modules' as m;

// Invert color system values
@function invert($theme, $color, $number) {
  // If the theme is light, invert the number
  @if $theme == 'light' {
    /* Use $minStep when $number == $maxStep
       1200 - 1200 = 0 (which does not exist)
       Instead, when $maxStep is input, use $minStep */
    @if $number == m.$maxStep {
      $number: m.$minStep;
    } @else {
      $number: m.$maxStep - $number;
    }
  }

  @return m.c($color, #{$number});
}

// Create styles for specified theme and color
@mixin create-theme($theme, $color) {
  // Base colors
  $c-element: invert($theme, $color, 1175);
  $c-background: invert($theme, $color, 1200);
  $c-divider: invert($theme, $color, 1125);
  $c-blue: m.c('blue', '800');

  // Text colors
  $c-text-default: invert($theme, $color, 100);
  $c-text-light: invert($theme, $color, 400);
  $c-bullet-point: invert($theme, $color, 600);
  $c-text-decoration: $c-blue;

  // Border colors
  $c-border-default: invert($theme, $color, 1050);
  $c-border-active: invert($theme, $color, 400);
  $c-border-focus: invert($theme, $color, 100);
  $c-connector: invert($theme, $color, 900);

  .#{$theme} {
    background-color: $c-background;
    color: $c-text-default;
    // First color thumb, second color track
    scrollbar-color: invert($theme, $color, 300) $c-background;

    &::-webkit-scrollbar {
      background-color: invert($theme, $color, 150);
    }

    &::-webkit-scrollbar-thumb {
      background-color: invert($theme, $color, 300);
    }

    ::selection {
      background-color: invert($theme, $color, 200);
      color: $c-background;
    }

    .react-tooltip {
      background-color: invert($theme, $color, 1150);
      color: $c-text-default;
    }

    h2.small-caps {
      color: invert($theme, $color, 400);
    }

    a {
      &:link {
        color: $c-text-default;
      }

      &:visited {
        color: $c-text-default;
      }

      &:hover {
        text-decoration-color: $c-text-decoration;
      }
    }

    .hero .hero-text-container {
      .tags {
        .tag {
          background-color: $c-element;
          border: 1px solid $c-border-default;

          &:hover {
            border-color: $c-text-decoration;
          }
        }

        a {
          @include m.text-decoration($c-text-decoration);
          // These two mixins can conflict for transition-property
          @include m.focus($c-border-focus);
          color: $c-text-light;

          svg.chevron-right path {
            @include m.transition(fill);
            fill: $c-text-light;
          }

          &:hover {
            color: $c-text-default;

            svg.chevron-right path {
              fill: $c-text-default;
            }
          }
        }
      }

      p {
        color: $c-text-light;
      }
    }

    header {
      &.site-header nav {
        box-shadow: 0 0 0 2px $c-border-default inset;
        background-color: $c-background;

        &.menu-btn {
          @include m.focus($c-border-focus);
        }

        &.menu-nav {
          > a::after {
            background-color: $c-divider;
          }
        }
      }

      .btn {
        @include m.focus($c-border-focus, $inset: true);
        @include m.text-decoration($c-text-decoration);
        background-color: transparent;
        text-decoration-color: transparent;
        color: $c-text-default;

        &:hover {
          text-decoration-color: $c-text-decoration;
        }

        // Active nav-btn (i.e. current page)
        &.nav-btn:not(.name) {
          &.active {
            text-decoration: m.$underline-active;
            text-decoration-color: $c-text-decoration;
            text-underline-offset: m.$underline-offset-active;
          }
        }

        svg path {
          fill: $c-text-default;
        }

        // Divider
        &.icon-btn {
          &:not(:last-child) {
            &::after {
              background-color: $c-divider;
            }
          }
        }
      }
    }

    .text-link {
      @include m.focus($c-border-focus);
      @include m.text-decoration($c-text-decoration);

      svg path {
        fill: $c-text-default;
      }
    }

    .external-link {
      @include m.focus($c-border-focus);
      @include m.text-decoration($c-text-decoration, $resting-underline: true);

      svg path {
        fill: $c-text-light;
      }
    }

    .about {
      ul li a.text-link {
        @include m.text-decoration(
          $c-text-decoration,
          $resting-underline: true
        );
      }
    }

    .colors {
      section .color-container {
        background-color: $c-element;
        box-shadow: 0 0 0 1px inset $c-border-default;
      }

      .color-row {
        .color-name {
          color: $c-text-default;
        }

        &.invert-dark .color-name {
          color: $c-background;
        }

        &.invert-light .color-name {
          color: $c-background;
        }
      }
    }

    .card-group {
      &.more-projects {
        border-top: 1px solid $c-border-default;
      }
    }

    .card {
      @include m.focus($c-border-focus, m.$br-inset, $inset: true);
      @include m.text-decoration(
        $c-text-decoration,
        $target-element: '.card-name'
      );
      background-color: $c-element;
      position: relative;

      &:hover:not(:focus-visible) {
        &::before {
          box-shadow: 0 0 0 2px inset $c-border-active;
        }
      }

      &::before {
        box-shadow: 0 0 0 2px inset $c-border-default;
        border-radius: m.$br-default;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }

      .card-description {
        color: $c-text-light;
      }

      .image-container {
        box-shadow: 0 2px 0 0 $c-border-default;
      }
    }

    .image-container {
      box-shadow: 0 0 0 2px $c-border-default;
      background-color: invert($theme, $color, 1100);
    }

    .thumbnail {
      .thumbnail-inner {
        color: $c-text-default;

        .thumbnail-text {
          .thumbnail-details {
            font-size: 1.4rem;
            color: invert($theme, $color, 300);
            margin-top: 4px;
          }
        }
      }
    }

    .bullet-point {
      margin: 0 4px;
      color: $c-bullet-point;
    }

    .page-header {
      .text-container {
        .return-link {
          color: $c-text-light;

          svg path {
            fill: $c-text-light;
          }
        }

        .subtitle {
          color: $c-text-light;
        }
      }
    }

    .about-brief {
      .text-link {
        box-shadow: 0 0 0 2px inset $c-border-default;
      }
    }

    .project-details {
      // Header background color
      > header {
        background-color: invert($theme, $color, 1150);
        box-shadow: 0 0 0 100vmax invert($theme, $color, 1150);
        clip-path: inset(-80px -100vmax 0);
      }
    }

    .icon-category {
      .icon-group {
        .icon-container {
          @include m.transition(box-shadow);
          box-shadow: 0 0 0 2px inset $c-border-default;

          &:hover {
            box-shadow: 0 0 0 2px inset $c-border-active;
          }

          svg {
            * {
              fill: $c-text-default;
            }

            &.spinner-circle path:first-child {
              fill: invert($theme, $color, 800);
            }
          }

          span {
            color: $c-text-light;
          }
        }
      }
    }

    .deploy {
      box-shadow: 0 0 0 2px inset $c-border-default;

      > header {
        color: $c-text-default;

        .label-counts svg * {
          fill: $c-bullet-point;
        }

        .date-container span {
          color: $c-text-light;
        }

        button {
          @include m.focus($c-border-focus, m.$br-inset, $inset: true);

          &:hover {
            background-color: invert($theme, $color, 1150);
          }

          svg path {
            fill: $c-text-default;
          }
        }
      }
    }

    .branch {
      .connector-container {
        .connector {
          border-left: 2px dotted $c-connector;
        }

        svg > * {
          fill: $c-connector;
        }
      }

      header {
        .header-text-container .issue-details .label {
          box-shadow: 0 0 0 1px inset $c-border-default;
        }
      }

      .header-text-container .header-group > svg path {
        fill: $c-border-default;
      }
    }

    code {
      background-color: invert($theme, $color, 1150);
      box-shadow: 0 0 0 1px invert($theme, $color, 1100) inset;
    }

    footer {
      border-top: 1px solid invert($theme, $color, 1100);
      color: $c-text-light;
    }
  }

  @include m.sm {
    .#{$theme} {
      .card .image-container {
        box-shadow: -2px 0 0 0 $c-border-default, 0 -2px 0 0 $c-border-default;
      }
    }
  }
}

$themes: 'dark', 'light';

@each $theme in $themes {
  @include create-theme($theme, 'gray');
}
