@use '../../../../styles/modules' as m;
@use 'sass:math';

.page.sub.projects {
  display: flex;
  flex-direction: column;

  .page-header {
    @include m.transition(margin-bottom, padding);
    margin-bottom: m.outer-spacing('xxs');
    padding: math.div(m.outer-spacing('xxs'), 1.5) 0 m.outer-spacing('xxs');
    min-height: calc(50vh - 80px);

    .text-container {
      @include m.transition(margin-bottom);
      margin-bottom: m.inner-spacing('xxs');
      z-index: 1;
    }

    .image-container {
      display: flex;
      z-index: 1;
      aspect-ratio: 2/1;
      overflow: hidden;

      img,
      span {
        max-width: 100%;
        border-radius: m.$br-default;
        flex: 1;
      }

      img {
        object-fit: cover;
        object-position: top;
      }
    }
  }

  .section {
    .image-container {
      @include m.transition(margin);

      &:not(:last-child) {
        margin: 0 0 m.inner-spacing('xxs');
      }
    }
  }

  .icon-category {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 2.4rem;
    }

    .icon-category-name {
      @include m.vertical-trim(1.4);
      margin-bottom: 0.8rem;
    }

    .icon-group {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      margin: 0 -0.4rem;

      .icon-container {
        display: flex;
        flex-direction: column;
        flex: 0 0 calc(33.333% - 0.8rem);
        padding: 1.6rem;
        margin: 0.8rem 0.4rem 0;
        max-width: calc(33.333% - 0.8rem);
        overflow: hidden;

        svg {
          margin: 0 auto 1.2rem;
        }

        span {
          @include m.vertical-trim(1.2);
          text-overflow: ellipsis;
          overflow-x: clip;
          white-space: nowrap;
          text-align: center;
        }
      }
    }
  }
}

@include m.xs {
  .page.sub.projects {
    .page-header {
      margin-bottom: m.outer-spacing('xs');
      padding: math.div(m.outer-spacing('xxs'), 1.25) 0 m.outer-spacing('xs');

      .text-container {
        margin-bottom: m.inner-spacing('xs');
      }
    }

    .section {
      .image-container {
        &:not(:last-child) {
          margin: 0 0 m.inner-spacing('xs');
        }
      }
    }
  }
}

@include m.sm {
  .page.sub.projects {
    .page-header {
      flex-direction: row;
      margin-bottom: m.outer-spacing('sm');
      padding: m.outer-spacing('sm') 0;

      .text-container {
        flex: 3;
        margin-right: 3.2rem;
        margin-bottom: 0;
      }

      .image-container {
        aspect-ratio: 4/3;
        flex: 2;

        img {
          object-position: top left;
        }
      }
    }

    .section {
      .image-container {
        &:not(:last-child) {
          margin: 0 0 m.inner-spacing('sm');
        }
      }
    }

    .icon-category .icon-group {
      .icon-container {
        flex: 0 0 calc(25% - 0.8rem);
        max-width: calc(25% - 0.8rem);
      }
    }
  }
}

@include m.md {
  .page.sub.projects {
    .page-header {
      .text-container {
        margin-right: 4.8rem;
      }
    }
  }
}
