@use '../../../styles/modules' as m;

.card {
  @include m.transition(margin-bottom);
  display: flex;
  flex-direction: column-reverse;
  border-radius: m.$br-default;

  &:not(:last-child) {
    margin-bottom: m.inner-spacing('xxs');
  }

  .image-container {
    height: 96px;
    max-width: 100%;
    border-radius: m.$br-default m.$br-default 0 0;
    overflow: hidden;
    transition: height m.$timing ease-out;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: top;
    }

    // Offset box-shadow for skeleton
    > span {
      padding: 2px 2px 0 2px;
    }
  }

  .card-text {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 1.6rem 2.4rem 2rem;

    .card-name {
      font-weight: bold;
      font-size: 1.8rem;
      margin-bottom: 0.4rem;
    }

    .card-description {
      font-size: 1.4rem;
    }
  }
}

@include m.xs {
  .card {
    &:not(:last-child) {
      margin-bottom: m.inner-spacing('xs');
    }

    .image-container {
      height: 128px;
    }
  }
}

@include m.sm {
  .card {
    flex-direction: row;

    &:not(:last-child) {
      margin-bottom: m.inner-spacing('sm');
    }

    .image-container {
      height: 160px;
      margin-top: 4.8rem;
      border-radius: m.$br-default 0 m.$br-default 0;
      flex: 1;

      // Offset box-shadow for skeleton
      > span {
        padding: 0 2px 2px 0;
      }
    }

    .card-text {
      margin: 4.8rem;

      .card-name {
        font-size: 2rem;
      }
    }
  }
}
