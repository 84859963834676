@use '../../../styles/modules' as m;

.colors {
  .section {
    > div {
      flex-direction: row;
    }

    header {
      user-select: none;
    }

    .color-container {
      display: flex;
      flex: 1;
      flex-direction: column;
      font-family: 'DM Mono';
      padding: 1.6rem 2.4rem;
      border-radius: m.$br-default;

      &:not(:last-child) {
        margin-right: 3.2rem;
      }

      .whitespace {
        white-space: pre;
      }

      .color-row {
        @include m.transition(font-size);
        display: flex;
        margin-bottom: 0.8rem;
        font-size: 1.4rem;

        .color-name {
          padding: 0 0.4rem;
          border-radius: m.$br-inset;
          margin-right: 0.4rem;
        }
      }
    }
  }
}

@include m.sm {
  .colors section .color-container {
    padding: 1.6rem 3.2rem;

    .color-row {
      font-size: 1.6rem;
    }
  }
}
