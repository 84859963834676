@use '../../../styles/modules' as m;

.home {
  display: flex;
  flex-direction: column;
  align-items: center;

  .about-brief {
    @include m.transition(margin-bottom);
    margin-bottom: m.outer-spacing('xxs');
    display: flex;
    flex-direction: column;
    max-width: 1024px;

    header {
      display: flex;

      h2.small-caps {
        @include m.transition(margin);
        margin: 0 0 m.inner-spacing('xxs');
      }
    }

    .inner-container {
      display: flex;
      flex-direction: column;

      .image-container {
        @include m.transition(all);
        flex: 1;
        margin: 0 0 m.inner-spacing('xxs');
        overflow: hidden;
        aspect-ratio: 2/1;
        border-radius: m.$br-default;
        width: 100%;

        img {
          object-fit: cover;
          object-position: 100% 66%;
          width: 100%;
          height: 100%;
        }
      }

      .text-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;

        .text-link {
          @include m.transition(all);
          margin-top: 0.8rem;
          width: 100%;
        }

        p {
          margin-top: 0;

          &.callout {
            @include m.vertical-trim(2, 1.4, 0.8);
            font-weight: 700;
            margin-bottom: m.$text-spacing;
            padding: 0.8rem 0;
          }

          &.excerpt {
            @include m.vertical-trim(1.6, 1.8);
            margin-bottom: m.$text-spacing;
          }
        }
      }
    }
  }
}

@include m.xs {
  .home {
    .about-brief {
      margin-bottom: m.outer-spacing('xs');

      header h2.small-caps {
        margin: 0 0 m.inner-spacing('xs');
      }

      .inner-container {
        .image-container {
          display: flex;
          flex-direction: column;
          margin: 0 0 m.inner-spacing('xs');
        }

        .text-container {
          p.callout {
            @include m.vertical-trim(2.2, 1.4, 1.2);
          }

          .text-link {
            margin-top: 1.2rem;
            width: 50%;
          }
        }
      }
    }
  }
}

@include m.sm {
  .home {
    .about-brief {
      margin-bottom: m.outer-spacing('sm');

      header h2.small-caps {
        margin: 0 0 m.inner-spacing('sm');
      }

      .inner-container {
        flex-direction: row;

        .image-container {
          margin: 0 m.inner-spacing('sm') 0 0;
          aspect-ratio: 3/2;
          align-self: flex-start;

          img {
            object-position: 100% 72%;
          }
        }

        .text-container {
          p.callout {
            @include m.vertical-trim(2.4, 1.4, 1.6);
          }

          .text-link {
            margin-top: 1.6rem;
          }
        }
      }
    }
  }
}
