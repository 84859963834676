@use '../../../styles//modules' as m;

.image-container {
  display: flex;
  border-radius: m.$br-default;

  img {
    transition: opacity m.$timing ease-out;
    opacity: 1;

    // Hide alt text while loading in Firefox
    &.loading {
      color: transparent;
      opacity: 0;
    }
  }

  span {
    display: flex;
    flex: 1;
    border-radius: inherit;
  }
}
