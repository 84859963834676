@use './variables' as v;
@use './functions' as f;
@use 'sass:math';

@mixin box-shadow-offset($offset, $color, $inset: '') {
  @if $inset == 'inset' {
    box-shadow: 0 (-$offset) 0 0 $color inset, $offset 0 0 0 $color inset,
      0 $offset 0 0 $color inset, (-$offset) 0 0 0 $color inset;
  } @else {
    box-shadow: 0 (-$offset) 0 0 $color, $offset 0 0 0 $color,
      0 $offset 0 0 $color, (-$offset) 0 0 0 $color;
  }
}

@mixin site-max-width($width) {
  max-width: $width;
  margin: 0 auto;
}

@mixin focus($color, $br: inherit, $inset: false) {
  // Define variables
  $box-shadow: '';
  $value: '';
  $transform: '';

  @if $inset {
    // Inset focus style
    $value: 8px;
    $transform: (
      'size': calc(100% - $value),
      'position': math.div($value, 2),
    );
    $box-shadow: (
      'default': 0 0 0 0 transparent inset,
      'active': 0 0 0 2px #{$color} inset,
    );
  } @else {
    // Expanded focus style
    $value: 8px;
    $transform: (
      'size': calc(100% + $value),
      'position':
        -(
          math.div($value, 2),
        ),
    );
    $box-shadow: (
      'default': 0 0 0 0 transparent,
      'active': 0 0 0 2px #{$color},
    );
  }

  position: relative;

  &::before {
    content: '';
    position: absolute;
    transition: all ease-out v.$timing;
    border-radius: $br;
    width: map-get($transform, 'size');
    height: map-get($transform, 'size');
    left: map-get($transform, 'position');
    top: map-get($transform, 'position');
  }

  &:focus-visible {
    outline: none;

    &::before {
      box-shadow: map-get($box-shadow, 'active');
    }
  }
}

@mixin transition($properties...) {
  transition-timing-function: ease-out;
  transition-duration: v.$timing;
  transition-property: $properties;
}

@mixin text-decoration(
  $color,
  $resting-underline: false,
  $target-element: false
) {
  text-decoration: solid none 0 transparent;

  // Target element: child of <a> where underline should be applied (string)
  @if $target-element {
    #{$target-element} {
      // Hover out
      @include transition(text-decoration, text-underline-offset);
      text-decoration: v.$underline-none transparent;
      text-underline-offset: 0;
    }

    &:hover {
      #{$target-element} {
        // Hover in
        @include transition(text-decoration, text-underline-offset);
        text-decoration: v.$underline-default $color;
        text-underline-offset: v.$underline-offset-default;
      }

      &:active #{$target-element} {
        text-underline-offset: v.$underline-offset-active;
      }
    }
  }
  // Resting underline: Show underline by default (boolean)
  @else if $resting-underline {
    @include transition(text-decoration, text-underline-offset);
    text-decoration: v.$underline-default $color;
    text-underline-offset: v.$underline-offset-default;

    &:focus {
      text-underline-offset: v.$underline-offset-default;
    }

    &:active {
      text-underline-offset: v.$underline-offset-active;
    }
  }
  // Default use case
  @else {
    @include transition(text-decoration, text-underline-offset);
    text-decoration: v.$underline-none transparent;
    text-underline-offset: 0;

    &:hover {
      @include transition(text-decoration, text-underline-offset);
      text-decoration: v.$underline-default $color;
      text-underline-offset: v.$underline-offset-default;

      &:active {
        text-underline-offset: v.$underline-offset-active;
      }
    }
  }
}

@mixin vertical-trim($font-size, $line-height: normal, $padding: false) {
  // DM Sans Font Metrics
  $fm-em-square: 0.1;
  $fm-cap-height: 0.7;
  $fm-ascender: 0.992;
  $fm-descender: 0.31;
  $fm-x-height: 0.496;
  $fm-line-gap: 0;

  // Convert decimal to whole number
  $font-size-computed: $font-size * 10;

  $distance-top: ($fm-ascender - $fm-cap-height) * $font-size-computed;
  $distance-bottom: $fm-descender * $font-size-computed;

  @if $line-height != normal {
    // Transform unitless line-height into pixels, relative to font-size
    $line-height-computed: $line-height * $font-size-computed;

    $content-area: $line-height-computed -
      ($font-size-computed * $fm-cap-height);

    $distance-top: math.div($content-area, 2);
    $distance-bottom: math.div($content-area, 2);
  }

  font-size: $font-size + rem;
  line-height: $line-height;
  // background-color: midnightblue;

  @if $padding {
    padding-top: $padding + rem;
    padding-bottom: $padding + rem;
  }

  &::before {
    content: '';
    display: block;
    margin-top: $distance-top * -0.1rem;
  }

  &::after {
    content: '';
    display: block;
    margin-top: $distance-bottom * -0.1rem;
  }
}

// Media queries

@mixin min-width($breakpoint) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}

@mixin max-width($breakpoint) {
  @media screen and (max-width: ($breakpoint - 1px)) {
    @content;
  }
}

@mixin xs {
  @media screen and (min-width: f.breakpoint('xs')) {
    @content;
  }
}

@mixin sm {
  @media screen and (min-width: f.breakpoint('sm')) {
    @content;
  }
}

@mixin md {
  @media screen and (min-width: f.breakpoint('md')) {
    @content;
  }
}

@mixin lg {
  @media screen and (min-width: f.breakpoint('lg')) {
    @content;
  }
}

@mixin xl {
  @media screen and (min-width: f.breakpoint('xl')) {
    @content;
  }
}
