@use 'sass:math';

$sans-serif: sans-serif;
$header-height: 80px;
$page-padding: 48px;
$timing: 0.1s;
$max-width: 1024px;
$soft-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
$flat-shadow: 1px 2px 0px 0px rgba(0, 0, 0, 0.08);
$nav-spacing: 2.4rem;

// Underline
$underline-default: solid underline 1px;
$underline-active: solid underline 2px;
$underline-none: solid underline 0;
$underline-offset-default: 3px;
$underline-offset-active: 5px;

// Border Radius
$br-default: 8px;
$br-inset: 4px;

// Color Map
// Increment between steps
$increment: 25;
// Total number of steps
$stepCount: 48;
// Minimum numerical value for color system
$minStep: $increment;
// Maximum numerical value for color system
$maxStep: $increment * $stepCount;

$breakpoints: (
  'xxs': 0,
  'xs': 512px,
  'sm': 768px,
  'md': 1024px,
  'lg': 1280px,
  'xl': 1536px,
);

$nav-spacing: (
  'xxs': 2rem,
  'xs': 2.4rem,
);

$h-spacing: (
  'xxs': 2.4rem,
  'xs': 4.8rem,
  'sm': 6.4rem,
  'md': 8rem,
);

$inner-spacing: (
  'xxs': 2.8rem,
  'xs': 3rem,
  'sm': 3.2rem,
);

$text-spacing: 2.8rem;

$outer-spacing: (
  'xxs': map-get($inner-spacing, 'xxs') * 2,
  'xs': map-get($inner-spacing, 'xs') * 2,
  'sm': map-get($inner-spacing, 'sm') * 2,
);

$pct-one-third: math.div(100, 3) + #{'%'};
$pct-one-half: math.div(100, 2) + #{'%'};

$one-third: math.div(1, 3);
$two-thirds: math.div(2, 3);

:export {
  increment: $increment;
  stepCount: $stepCount;
  timing: $timing;
  breakpoints: {
    xxs: map-get($breakpoints, 'xxs');
    xs: map-get($breakpoints, 'xs');
    sm: map-get($breakpoints, 'sm');
    md: map-get($breakpoints, 'md');
    lg: map-get($breakpoints, 'lg');
    xl: map-get($breakpoints, 'xl');
  }
}
