@use '../../../../styles/modules' as m;

.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  max-width: 1024px;

  .hero-text-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    flex: 2;
    width: 100%;

    h1 {
      @include m.transition(all);
      margin: 0 0 3.6rem;
    }

    p {
      @include m.transition(all);
      @include m.vertical-trim(1.6, 1.4);
      margin: 0;
    }

    .tag-container {
      @include m.transition(margin-top);
      display: flex;
      justify-content: center;
      margin-top: 1.6rem;

      .tags {
        @include m.transition(font-size);
        display: flex;
        align-items: baseline;
        justify-content: center;
        flex-wrap: wrap;
        font-size: 1.4rem;

        .child {
          @include m.transition(padding, margin, color, text-underline-offset);
          white-space: nowrap;
          padding: 0.2rem 0.8rem;
          margin: 0.6rem;
        }

        .tag {
          @include m.transition(border-color);
          cursor: default;
          border-radius: 1.6rem;
        }

        a {
          border-radius: m.$br-default;

          svg.chevron-right {
            transform: translateY(1px);
          }
        }
      }
    }
  }
}

@include m.xs {
  .hero {
    .hero-text-container {
      h1 {
        margin: 0 0 m.inner-spacing('xs');
      }

      p {
        @include m.vertical-trim(1.8, 1.4);
      }

      .tag-container {
        margin-top: 2.4rem;

        .tags {
          font-size: 1.6rem;

          .child {
            padding: 0.2rem 0.8rem;
            margin: 0.8rem;
          }
        }
      }
    }
  }
}

@include m.sm {
  .hero {
    .hero-text-container {
      flex: 1;

      h1 {
        margin: 0 0 m.inner-spacing('sm');
      }

      p {
        @include m.vertical-trim(2, 1.4);
      }
    }

    .image-container {
      margin-left: 3.2rem;
      flex: 1;
    }
  }
}

@include m.md {
  .hero {
    .hero-text-container {
      flex: 2;
    }

    .image-container {
      margin-left: 4.8rem;
    }
  }
}
