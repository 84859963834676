@use '../../../styles/modules' as m;

.section {
  @include m.transition(margin-bottom);
  display: flex;
  flex-direction: column;
  margin-bottom: m.outer-spacing('xxs');

  header {
    display: flex;
    flex-direction: column;
    flex: 1;

    h2 {
      margin: 0 0 m.$text-spacing;
    }
  }

  > div {
    display: flex;
    flex: 3;
    flex-direction: column;

    ul {
      padding-left: m.$text-spacing;

      li {
        margin-bottom: 0.4rem;
        list-style: circle;
        padding-left: 0.4rem;

        &:last-child {
          margin: 0;
        }
      }
    }

    > p,
    > ul {
      @include m.vertical-trim(1.6, 1.8);
      margin: 0 0 m.$text-spacing;

      &:last-child {
        margin-bottom: 0;
      }
    }

    img {
      max-width: 100%;
      border-radius: m.$br-default;
    }
  }
}

@include m.xs {
  .section {
    margin-bottom: m.outer-spacing('xs');

    header h2 {
      margin: 0 0 m.$text-spacing;
    }

    > div {
      > p,
      > ul {
        margin: 0 0 m.$text-spacing;
      }
    }
  }
}

@include m.sm {
  // When header.site-header has this style (applied in JS), apply this top value
  header.site-header[style='transition: transform 0.3s ease-out 0s;']
    + main
    section
    > header {
    top: 104px;
  }

  .section {
    flex-direction: row;
    margin-bottom: m.outer-spacing('sm');

    header {
      transition: top 0.3s ease-out;
      height: 24px;
      position: sticky;
      top: 24px;
      margin: 0 m.inner-spacing('sm') 0 0;

      h2 {
        margin: 0;
      }
    }

    > div {
      > p,
      > ul {
        margin: 0 0 m.$text-spacing;
      }
    }
  }
}
