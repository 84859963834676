@use '../../../styles/modules' as m;

.card-group {
  @include m.transition(margin-bottom);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1024px;
  margin-bottom: m.outer-spacing('xxs');

  .card-group-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    h2 {
      @include m.transition(margin);
      margin: 0 0 m.inner-spacing('xxs');
      font-size: 1.4rem;
    }

    a {
      margin-left: 24px;
    }
  }

  .card-group-container {
    display: flex;
    flex-direction: column;
  }

  &.more-projects {
    @include m.transition(margin-top, padding-top);
    padding-top: m.outer-spacing('xxs');
  }
}

@include m.xs {
  .card-group {
    margin-bottom: m.outer-spacing('xs');

    .card-group-header h2 {
      font-size: 1.6rem;
      margin: 0 0 m.inner-spacing('xs');
    }

    &.more-projects {
      padding-top: m.outer-spacing('xs');
    }
  }
}

@include m.sm {
  .card-group {
    margin-bottom: m.outer-spacing('sm');

    .card-group-header h2 {
      margin: 0 0 m.inner-spacing('sm');
    }

    &.more-projects {
      padding-top: m.outer-spacing('sm');
    }
  }
}
