@use '../../../../styles/modules/' as m;
@use 'sass:math';

.branch {
  display: flex;
  border-radius: m.$br-default;

  .connector-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 auto;
    margin-top: -2px;

    svg.small-circle {
      position: relative;
      top: -8px;
    }

    .connector {
      flex: 1;
      margin: 0 0 3px;
    }
  }

  &.last-child {
    margin-bottom: 2.4rem;
  }

  &:not(.last-child) .branch-content {
    padding: 0 0 3.2rem;
  }

  .branch-content {
    display: flex;
    flex-direction: column;
    flex: 1;

    header {
      @include m.transition(margin);
      display: flex;
      margin: 0 1.6rem 0 2.4rem;
      border-radius: m.$br-default m.$br-default 0 0;
      flex: 2;

      .header-text-container {
        display: flex;
        flex: 1;
        flex-direction: column;

        .header-group {
          display: flex;
          align-items: baseline;
          margin: 0 0 1.6rem;

          h3 {
            @include m.vertical-trim(1.6);
            flex: 1;
            margin: 0;
          }
        }

        .issue-details {
          display: flex;
          align-items: center;

          .label {
            margin-right: 12px;
            font-size: 1.4rem;
            padding: 0 12px 1px;
            height: 2.8rem;
            border-radius: 1.6rem;
            display: flex;
            align-items: center;
          }

          // Conditional based on whether to show issue number
          // One is a external link, one is plain text
          a.external-link,
          .issue-number {
            margin-right: 0.8rem;
            font-size: 1.4rem;
            padding: 0 0.4rem;
          }
        }
      }
    }

    .list {
      flex: 3;

      ul {
        font-size: 1.4rem;
        padding-left: 1.6rem;
      }

      > ul {
        @include m.transition(margin);
        margin: 1.6rem 0 0 2.4rem;

        li {
          margin-bottom: 0.4rem;

          ul {
            margin: 0.4rem 0 0.8rem;
          }
        }
      }
    }
  }

  &.loading {
    .branch-content header {
      .header-text-container {
        .header-group {
          h3 span {
            width: 80%;
          }
        }

        .issue-details {
          .sk-labels {
            width: 20%;
          }

          .sk-issue-number {
            width: 10%;
            margin-right: 1.6rem;
          }
        }
      }
    }
  }
}

@include m.sm {
  .branch {
    .branch-content {
      flex-direction: row;

      header {
        margin-right: 1.6rem;
      }

      .list {
        > ul {
          margin: 0;
        }
      }
    }
  }
}
