@use '../../../styles/modules' as m;

.projects {
  .projects-container {
    display: flex;
    flex-wrap: wrap;
  }
}

@include m.sm {
  .projects {
    .projects-container {
      margin-left: -24px;
      margin-right: -24px;
    }
  }
}
