@use '../../../../styles/modules/' as m;
@use 'sass:math';

.deploy {
  @include m.transition(margin);
  margin: 0 0 m.inner-spacing('xxs');
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  border-radius: m.$br-default;

  > header {
    display: flex;
    flex: 1;
    align-items: center;

    .header-text-container {
      display: flex;
      align-items: baseline;
      flex-direction: column;
      flex: 1;
      padding: 1.6rem 0;

      .date-container {
        display: flex;
        align-items: baseline;
        width: 100%;
        justify-content: space-between;
        padding-right: 2.4rem;

        span {
          font-family: 'DM Mono';
          margin-left: 1.6rem;
          font-size: 1.4rem;
        }
      }

      h2 {
        @include m.vertical-trim(1.8);
        margin: 0;
      }

      .label-counts {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 1.2rem;

        div[class$='count'] {
          @include m.vertical-trim(1.4);
        }

        svg {
          margin: 0 0.8rem;
        }
      }
    }

    button {
      @include m.transition(background-color);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      box-shadow: none;
      cursor: pointer;
      border: 0;
      color: initial;
      padding: 0;
      height: 6.4rem;
      width: 4.8rem;
      margin-right: 0.8rem;
      border-radius: m.$br-inset;
    }
  }

  > .branch-container {
    @include m.transition(padding);
    padding: 0 1.6rem 0 1.6rem;
    margin-top: 1.6rem;
  }

  &.loading {
    > header .header-text-container {
      .label-counts {
        height: 10px;
        .sk-count {
          width: 15%;
        }
      }
    }
  }
}

@include m.xs {
  .deploy {
    margin: 0 0 m.inner-spacing('xs');
  }
}

@include m.sm {
  .deploy {
    margin: 0 0 m.inner-spacing('sm');

    > .branch-container {
      padding: 0 2.4rem 0 5.6rem;
    }
  }
}
