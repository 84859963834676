@use '../modules' as m;
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Mono&display=swap');

.firebase-emulator-warning {
  display: none;
}

* {
  box-sizing: border-box;
}

html {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 62.5%;
  overflow-y: scroll;
  transition: background-color ease-out m.$timing;
}

body {
  flex: 1;
  display: flex;
  margin: 0;
  font: normal normal normal 1.6rem/1.6 'DM Sans', m.$sans-serif;
}

#app {
  display: flex;
  flex: 1;
}

h1 {
  font-size: 2.6rem;
  margin: 2.4rem 0;
  transition: all m.$timing ease-out;

  &.display {
    @include m.vertical-trim(2.8, 1.4);
  }
}

h2 {
  font-size: 2.2rem;
  margin: 2rem 0;
  transition: all m.$timing ease-out;

  &.small-caps {
    @include m.vertical-trim(1.4);
    text-transform: uppercase;
    letter-spacing: 2px;
  }
}

h3 {
  font-size: 1.6rem;
  margin: 1.6rem 0;
  transition: all m.$timing ease-out;
}

code {
  font-family: 'DM Mono', monospace;
  border-radius: m.$br-inset;
  font-size: 1.2rem;
  padding: 0.2rem 0.4rem;
}

.App {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;

  main {
    margin: 0 m.h-spacing('xxs');
    transition: margin ease-out 0.3s;
    display: flex;
    justify-content: center;
    flex: 1;
  }

  .page {
    display: flex;
    flex-direction: column;
    max-width: m.$max-width;
    margin-top: 8rem;
    flex: 1;
  }
}

.btn {
  border: 0;

  &:hover {
    cursor: pointer;
  }
}

@include m.xs {
  h1 {
    font-size: 3rem;
    margin: 2.8rem 0;

    &.display {
      @include m.vertical-trim(3.6, 1.4);
    }
  }

  h2 {
    font-size: 2.4rem;
    margin: 2rem 0;

    &.small-caps {
      @include m.vertical-trim(1.6);
    }
  }

  h3 {
    font-size: 1.8rem;
    margin: 1.8rem 0;
  }

  .App main {
    margin: 0 m.h-spacing('xs');
  }
}

@include m.sm {
  h1 {
    font-size: 3.2rem;
    margin: 3rem 0;

    &.display {
      @include m.vertical-trim(4);
    }
  }

  h2 {
    font-size: 2.6rem;
    margin: 2.4rem 0;
  }

  h3 {
    font-size: 2rem;
    margin: 2rem 0;
  }

  .App main {
    margin: 0 m.h-spacing('sm');
  }
}

@include m.md {
  h1 {
    font-size: 3.2rem;
    margin: 3.2rem 0;

    &.display {
      @include m.vertical-trim(4.8);
    }
  }

  .App main {
    margin: 0 m.h-spacing('md');
  }
}
