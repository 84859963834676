@use '../../../styles/modules' as m;

.page-header {
  @include m.transition(padding);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: m.outer-spacing('xxs') 0;

  .text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .text-link {
      margin: 0 0 1.6rem -1.2rem;
    }

    h1 {
      margin: 0;
    }

    .subtitle {
      margin-top: 0.4rem;
    }
  }
}

@include m.xs {
  .page-header {
    padding: m.outer-spacing('xs') 0;
  }
}

@include m.sm {
  .page-header {
    padding: m.outer-spacing('sm') 0;
  }
}
