@use '../../../styles/modules' as m;

.about {
  .section > div {
    // Push column-based flex items to left
    // Fixes oversized link bug
    align-items: flex-start;
  }

  ul li {
    p {
      display: inline;
      margin: 0;
    }

    a {
      display: inline;
      margin: 0 0.2rem;
    }

    .text-link {
      font-size: 1.6rem;
      padding: 0;
    }
  }
}
